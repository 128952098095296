<template>
  <v-row>
    <FacilityPlaylist />
  </v-row>
</template>

<script>
import FacilityPlaylist from '@/components/features/FacilityPlaylist.vue';

export default {
  components: { FacilityPlaylist },
}
</script>
