<template>
  <v-col cols="12">
    <v-dialog
      v-model="showViewPlaylistModal"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <div class="col-sm-11 text-center">
            Facility Playlist
          </div>
          <div
            class="col-sm-1 pointer"
            aria-controls
            @click="close"
          >
            &times;
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="d-flex gap-10">
                <div v-if="facilityPlaylist && facilityPlaylist.color" class="circle" :style="circleStyle(facilityPlaylist.color)"></div>
                <text-field
                  v-if="facilityPlaylist && facilityPlaylist.title"
                  ref="nameField"
                  v-model="facilityPlaylist.title"
                  label="Name"
                  class="playlist-text-field"
                  required
                  :outlined="false"
                  :disabled="true"
                ></text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <v-btn
                  :plain="true"
                  :x-small="true"
                  @click="openEditModal()"
                >
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  Edit
                </v-btn>
                <v-btn
                  :plain="true"
                  :x-small="true"
                  color="primary"
                  @click="toggleFavorite(facilityPlaylist)"
                >
                  <v-icon v-if="facilityPlaylist && (facilityPlaylist.is_favorite !== null || facilityPlaylist.is_favorite !== undefined)">
                    {{ facilityPlaylist.is_favorite ? icons.mdiStar : icons.mdiStarOutline }}
                  </v-icon>
                  <span v-if="facilityPlaylist && facilityPlaylist.is_favorite">
                    Remove to Favorites
                  </span>
                  <span v-else>
                    Add to Favorites
                  </span>
                </v-btn>
                <v-btn
                  :plain="true"
                  :x-small="true"
                  color="error"
                  @click="deletePlaylist()"
                >
                  <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  Delete
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="facilityPlaylist && facilityPlaylist.place_of_services">
            <v-col cols="12">
              <div>
                <span>{{ facilityPlaylist.place_of_services.length }}</span>&nbsp;
                <span v-if="facilityPlaylist.place_of_services.length > 1">Facilities</span>
                <span v-else>Facility</span>
              </div>
            </v-col>
            <v-simple-table class="w-100">
              <template v-slot:default>
                <tbody>
                  <tr v-for="item in facilityPlaylist.place_of_services">
                    <td>{{ item.title }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <CreateUpdateFacilityPlaylist
      v-model="showCreatePlaylistModal"
      :openCreateModal="showCreatePlaylistModal"
      :createPayLoad="payLoad"
      @close="showCreatePlaylistModal = false"
    />
  </v-col>
</template>
<script>
import CreateUpdateFacilityPlaylist from "@/components/elements/modals/FacilityPlaylist/CreateUpdateFacilityPlaylist.vue";
import FacilityPlaylist from '@/mixins/FacilityPlaylist';
import {
  mdiSquareEditOutline,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  components: {
    CreateUpdateFacilityPlaylist,
  },
  mixins: [FacilityPlaylist],
  props: {
    showViewPlaylistModal: {
      type: Boolean,
      required: true,
    },
    facilityPlaylist: {
      type: Object,
      required: false,
      default: () => ({
        title: '',
        color: '',
        is_favorite: false,
        place_of_services: [],
      }),
    },
  },
  data() {
    return {
      isOnline: navigator.onLine,
      icons: {
        mdiSquareEditOutline,
        mdiStar,
        mdiStarOutline,
        mdiTrashCanOutline,
      },
    }
  },
  computed: {
    ...mapGetters('auth', { myFacilityPlaylist: 'facilityPlaylist', }),
  },
  methods: {
    openEditModal() {
      this.close()
      this.editList(this.facilityPlaylist)
    },

    async deletePlaylist() {
      const isDeleted = await this.deleteList(this.facilityPlaylist)

      if (isDeleted) {
        this.close()
      }
    },
  },
}
</script>

<style scoped>
.circle {
  position: relative;
  top: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
</style>

